// LoadingContext.tsx
import React, { createContext, useContext, useState, useCallback } from 'react';

interface LoadingContextState {
    isLoading: boolean;
    startLoading: () => void;
    stopLoading: () => void;
}

const defaultState: LoadingContextState = {
    isLoading: false,
    startLoading: () => {},
    stopLoading: () => {},
};

const LoadingContext = createContext<LoadingContextState>(defaultState);

export const useLoading = () => useContext(LoadingContext);

interface LoadingProviderProps {
    children: React.ReactNode;
}

export const LoadingProvider: React.FC<LoadingProviderProps> = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);

    const startLoading = useCallback(() => {
        setIsLoading(true);
    }, []);

    const stopLoading = useCallback(() => {
        setIsLoading(false);
    }, []);

    return (
        <LoadingContext.Provider value={{ isLoading, startLoading, stopLoading }}>
            {children}
        </LoadingContext.Provider>
    );
};







