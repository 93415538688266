import React, { useState, useEffect } from 'react';
import { FaFacebook } from 'react-icons/fa'; // Importing the Facebook icon

type UserProfileProps = {
    userId: string;
    userData: any;
};

const UserFacebook: React.FC<UserProfileProps> = ({ userId, userData }) => {
    const [userFacebookUrl, setUserFacebookUrl] = useState<string | null>(null);
    const API_KEY = process.env.REACT_APP_API_KEY; // Access the API key from the environment variable

    useEffect(() => {
        // Check existence of userData, user_info, getUserProfile, contact, and socialMedia properties
        if (userData && userData.user_info && userData.user_info.getUserProfile &&
            userData.user_info.getUserProfile.contact && userData.user_info.getUserProfile.contact.socialMedia &&
            userData.user_info.getUserProfile.contact.socialMedia.facebook) {
            const facebookUrl = userData.user_info.getUserProfile.contact.socialMedia.facebook;
            setUserFacebookUrl(facebookUrl);
        } else {
            setUserFacebookUrl(null); // Reset facebook URL in case the properties do not exist
        }
    }, [userId, userData]);  // dependency on userId and userData

    if (!userFacebookUrl) {
        return null;
    }

    return (
        <a href={userFacebookUrl} target="_blank" rel="noopener noreferrer" style={{
            display: 'inline-block', // Make the link inline-block to apply dimensions
            cursor: 'pointer', // Change cursor to indicate clickable
            textDecoration: 'none', // Remove default link styling
            color: '#f0f0f0', // Inherit the text color from the parent
            fontSize: '35px' // Adjust the size of the icon as needed
        }}>
            <FaFacebook />
        </a>
    );
}

export default UserFacebook;