// FullScreenLoading.tsx
import React from 'react';
import './FullScreenLoading.css'; // Import the CSS file for styling

const FullScreenLoading: React.FC = () => {
    return (
        <div className="fullscreen-loading">
            <div className="spinner"></div>
        </div>
    );
}

export default FullScreenLoading;
