import React, { useState, useEffect } from 'react';

type UserProfileProps = {
    userId: string;
    userData: any;
};

const UserIsPremium: React.FC<UserProfileProps> = ({ userId, userData }) => {
    const [isPremium, setIsPremium] = useState<boolean | null>(null); // It can be boolean or null (before data is loaded)
    const API_KEY = process.env.REACT_APP_API_KEY; // Access the API key from the environment variable

    useEffect(() => {
        const premiumStatus = userData?.user_info?.getUserProfile.isPremium;
        setIsPremium(premiumStatus);
    }, [userId, userData]);  // dependency on userId and userData

    // Show loading until the data is fetched
    if (isPremium === null) {
        return null;
    }

    // Render the premium image if isPremium is true, otherwise render nothing
    return (
        <div>
            {isPremium && <img src="/premium.png" alt="Premium" width="60px"/>}
        </div>
    );
}

export default UserIsPremium;