import React, { useState, useEffect } from 'react';
import { FaGlobe } from 'react-icons/fa'; // Importing the website (globe) icon

type UserProfileProps = {
    userId: string;
    userData: any;
};

const UserWebsite: React.FC<UserProfileProps> = ({ userId, userData }) => {
    const [userWebsiteUrl, setUserWebsiteUrl] = useState<string | null>(null);
    const apiKey = process.env.REACT_APP_API_KEY; // Access the API key from the environment variables

    useEffect(() => {
        if (userData && userData.user_info && userData.user_info.getUserProfile && userData.user_info.getUserProfile.contact
        && userData.user_info.getUserProfile.contact.website) {
            const websiteUrl = userData.user_info.getUserProfile.contact.website;
            setUserWebsiteUrl(websiteUrl);
        } else {
            setUserWebsiteUrl(null); // Reset website URL if userData or necessary nested properties do not exist
        }
    }, [userId, userData]);  // dependency on userId and userData

    if (!userWebsiteUrl || userWebsiteUrl == "") {
        return null;
    }

    return (
        <a href={userWebsiteUrl} target="_blank" rel="noopener noreferrer" style={{
            display: 'inline-block', // Make the link inline-block to apply dimensions
            cursor: 'pointer', // Change cursor to indicate clickable
            textDecoration: 'none', // Remove default link styling
            color: '#f0f0f0', // Inherit the text color from the parent
            fontSize: '35px' // Adjust the size of the icon as needed
        }}>
            <FaGlobe />
        </a>
    );
}

export default UserWebsite;