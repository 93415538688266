import React, { useState, useEffect } from 'react';
import { useLoading } from "./loading_screen/LoadingContext";

const placeholderImage = 'placeholder.png'; // Ensure this path is correct

type UserProfileProps = {
    userId: string;
    userData: any;
};

const UserFrontPicture: React.FC<UserProfileProps> = ({ userId, userData }) => {
    const [imageSrc, setImageSrc] = useState<string>(placeholderImage);

    useEffect(() => {
        if (userData && userData.user_info && userData.user_info.getUserProfile && userData.user_info.getUserProfile.profilePictureId) {
            const profilePicId = userData.user_info.getUserProfile.profilePictureId.split('/').pop();
            if (profilePicId) {
                const imageUrl = `https://img.prod-thepending.apprime.de/insecure/g:sm/plain/public/${userId}/${profilePicId}`;
                setImageSrc(imageUrl);
            }
        } else {
            setImageSrc(placeholderImage);
        }
    }, [userId, userData]);

    return (
        <div style={{
            width: '150px',
            height: '150px',
            overflow: 'hidden',
            borderRadius: '15px'
        }}>
            <img
                src={imageSrc}
                alt="Front Picture"
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                }}
            />
        </div>
    );
}

export default React.memo(UserFrontPicture);


