import React, { useState, useEffect } from "react";

type UserProfileProps = {
    userId: string;
    userData: any;
};

const UserCity: React.FC<UserProfileProps> = ({ userId, userData }) => {
    const [userCity, setUserCity] = useState<string | null>(null);

    useEffect(() => {
        if(userData && userData.user_info && userData.user_info.getUserProfile
            && userData.user_info.getUserProfile.location) {
            const city = userData.user_info.getUserProfile.location.city;
            setUserCity(city);
        }
    }, [userId, userData]);

    // don't render anything if userCity is null or empty
    if (!userCity || userCity == "Location not available") {
        return null;
    }

    // Display the location image and the city name
    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <img src="/location.png" alt="Location" style={{ width: '30px', height: '30px' }} />
            <span>{userCity}</span>
        </div>
    );
}

export default UserCity;
