import React from 'react';
import QRCode from 'qrcode.react';
const logo = 'logo.png'; // Path to your logo image

const QRCodeDisplay: React.FC = () => {
    return (
        <div style={{paddingTop: '10px'}}>
            <a href="https://thepending.art" target="_blank" rel="noopener noreferrer">
                <QRCode
                    value={window.location.href}
                    size={120} // Size of the QR code
                    level={"H"} // Error correction level
                    includeMargin={true} // Include margin
                    bgColor={"transparent"} // Set background color to transparent
                    fgColor={"#f0f0f0"} // You can customize the foreground color as needed
                    imageSettings={{
                        src: logo,
                        height: 34, // Height of the logo
                        width: 40, // Width of the logo
                        excavate: true, // The QR code will be drawn behind the image
                    }}
                />
            </a>
        </div>
    );
};

export default QRCodeDisplay;

