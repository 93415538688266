import React, { useEffect, useState } from 'react';

type UserProfileProps = {
    userId: string;
    userData: any;
};

const UserAbout: React.FC<UserProfileProps> = ({ userId, userData }) => {
    const [aboutText, setAboutText] = useState<string>('');

    useEffect(() => {
        if (userData && userData.user_info && userData.user_info.getUserProfile) {
            const aboutUser = userData?.user_info?.getUserProfile.about;
            if (aboutUser) {
                setAboutText(aboutUser);
            }
        }
    }, [userId, userData]);  // dependency on userId and userData

    const convertURLsToLinks = (text: string) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.split(urlRegex).map((part, index) =>
            urlRegex.test(part) ? (
                <a key={index} href={part} target="_blank" rel="noopener noreferrer">{part}</a>
            ) : (
                part
            )
        );
    };

    return aboutText ? (
        <p style={{ color: '#333', fontSize: '15px', textAlign: 'center' }}>
            {convertURLsToLinks(aboutText)}
        </p>
    ) : null;
}

export default React.memo(UserAbout);