import React, { useState, useEffect } from 'react';
import { FaYoutube } from 'react-icons/fa'; // Importing the YouTube icon

type UserProfileProps = {
    userId: string;
    userData: any;
};

const UserYouTube: React.FC<UserProfileProps> = ({ userId, userData }) => {
    const [userYouTubeId, setUserYouTubeId] = useState<string | null>(null);
    const apiKey = process.env.REACT_APP_API_KEY; // Access the API key from the environment variables

    useEffect(() => {
        if (userData && userData.user_info && userData.user_info.getUserProfile &&
            userData.user_info.getUserProfile.contact && userData.user_info.getUserProfile.contact.socialMedia
        && userData.user_info.getUserProfile.contact.socialMedia.youtube) {
            const youtubeId = userData.user_info.getUserProfile.contact.socialMedia.youtube;
            setUserYouTubeId(youtubeId);
        } else {
            setUserYouTubeId(null); // Reset YouTube ID in case it does not exist
        }
    }, [userId, userData]); // dependency on userId and userData

    if (!userYouTubeId) {
        return null;
    }

    // Construct the full YouTube URL
    const youTubeUrl = `${userYouTubeId}`;

    return (
        <a href={youTubeUrl} target="_blank" rel="noopener noreferrer" style={{
            display: 'inline-block', // Make the link inline-block to apply dimensions
            cursor: 'pointer', // Change cursor to indicate clickable
            textDecoration: 'none', // Remove default link styling
            color: '#f0f0f0', // Inherit the text color from the parent
            fontSize: '35px' // Adjust the size of the icon as needed
        }}>
            <FaYoutube />
        </a>
    );
}

export default UserYouTube;