import React, { useState, useEffect } from 'react';
import { FaTwitter } from 'react-icons/fa'; // Importing the Twitter icon

type UserProfileProps = {
    userId: string;
    userData: any;
};

const UserTwitter: React.FC<UserProfileProps> = ({ userId, userData }) => {
    const [userTwitterUsername, setUserTwitterUsername] = useState<string | null>(null);
    const apiKey = process.env.REACT_APP_API_KEY; // Access the API key from the environment variables

    useEffect(() => {
        if (userData && userData.user_info && userData.user_info.getUserProfile &&
            userData.user_info.getUserProfile.contact && userData.user_info.getUserProfile.contact.socialMedia
        && userData.user_info.getUserProfile.contact.socialMedia.twitter) {
            const twitterUsername = userData.user_info.getUserProfile.contact.socialMedia.twitter;
            setUserTwitterUsername(twitterUsername);
        } else {
            setUserTwitterUsername(null); // Reset Twitter username if userData or necessary properties do not exist
        }
    }, [userId, userData]);  // dependency on userId and userData

    if (!userTwitterUsername) {
        return null;
    }

    // Construct the full Twitter URL
    const twitterUrl = `https://twitter.com/${userTwitterUsername}`;

    return (
        <a href={twitterUrl} target="_blank" rel="noopener noreferrer" style={{
            display: 'inline-block', // Make the link inline-block to apply dimensions
            cursor: 'pointer', // Change cursor to indicate clickable
            textDecoration: 'none', // Remove default link styling
            color: '#f0f0f0', // Inherit the text color from the parent
            fontSize: '35px' // Adjust the size of the icon as needed
        }}>
            <FaTwitter />
        </a>
    );
}

export default UserTwitter;