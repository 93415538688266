import React, { useEffect, useState } from 'react';

const backBufferImage = 'screen.png';


type UserProfileProps = {
    userId: string;
    userData: any;
};

// @ts-ignore
const UserBackPicture: React.FC<UserProfileProps> = ({ userId, userData }) => {
    const [imageSrc, setImageSrc] = useState<string>(backBufferImage);

    useEffect(() => {
        // Initialize a placeholder for the image URL
        let imageUrl = backBufferImage;

        // Check if all necessary data is available
        if (userData && userData.user_info && userData.user_info.getUserProfile){
            const backgroundImageId = userData?.user_info?.getUserProfile.backgroundImageId;
            if(backgroundImageId){
                const lastString = backgroundImageId.split('/').pop();
                imageUrl = lastString
                    ? `https://img.prod-thepending.apprime.de/insecure/g:sm/plain/public/${userId}/${lastString}`
                    : backBufferImage;
            }
        }

        const finalImageSrc = imageUrl.includes('{delete}') ? backBufferImage : imageUrl;

        setImageSrc(finalImageSrc);
    }, [userId, userData]);
    return (
        <img
            src={imageSrc}
            style={{
                width: '300px',
                height: '250px',
                objectFit: 'cover',
                maxWidth: '400px',
                maxHeight: '300px',
                borderTopLeftRadius: '0',
                borderTopRightRadius: '0',
                borderBottomLeftRadius: '15px',
                borderBottomRightRadius: '15px',
            }}
        />
    );
};

export default UserBackPicture;