import React, { useState, useEffect } from 'react';
import { FaPhone } from 'react-icons/fa'; // Importing the phone icon

type UserProfileProps = {
    userId: string;
    userData: any;
};

const UserPhone: React.FC<UserProfileProps> = ({ userId, userData }) => {
    const [userPhoneNumber, setUserPhoneNumber] = useState<string | null>(null);
    const API_KEY = process.env.REACT_APP_API_KEY; // Access the API key from the environment variables

    useEffect(() => {
        // Check for userData, user_info, getUserProfile, and contact properties
        if (userData && userData.user_info && userData.user_info.getUserProfile &&
            userData.user_info.getUserProfile.contact && userData.user_info.getUserProfile.contact.phone) {
            const phoneNumber = userData.user_info.getUserProfile.contact.phone;
            setUserPhoneNumber(phoneNumber);
        } else {
            setUserPhoneNumber(null); // Reset phone number in case the properties do not exist
        }
    }, [userId, userData]);  // dependency on userId and userData

    if (!userPhoneNumber || userPhoneNumber == "") {
        return null;
    }

    return (
        <a href={`tel:${userPhoneNumber}`} style={{
            display: 'inline-block', // Make the link inline-block to apply dimensions
            cursor: 'pointer', // Change cursor to indicate clickable
            textDecoration: 'none', // Remove default link styling
            color: '#f0f0f0',  // Inherit the text color from the parent
            fontSize: '35px' // Adjust the size of the icon as needed
        }}>
            <FaPhone />
        </a>
    );
}

export default UserPhone;