import React, { useState, useEffect } from 'react';

type UserProfileProps = {
    userId: string;
    userData: any;
};

const UserName: React.FC<UserProfileProps> = ({ userId, userData }) => {
    const [userFirstName, setUserFirstName] = useState('');
    const [userLastName, setUserLastName] = useState('');
    const [userArtistName, setUserArtistName] = useState('');
    const API_KEY = process.env.REACT_APP_API_KEY; // Access the API key from the environment variables

    useEffect(() => {
        if(userData && userData.user_info && userData.user_info.getUserProfile) {
            const firstName = userData?.user_info?.getUserProfile.firstName;
            const lastName = userData?.user_info?.getUserProfile.lastName;
            const artistName = userData?.user_info?.getUserProfile.artistName;

            setUserFirstName(firstName);
            setUserLastName(lastName);
            setUserArtistName(artistName);
        }
    }, [userId, userData]);  // dependency on userId and userData

    // Function to check if the data is not null or empty
    const isValidData = (data: string) => data && data.trim().length > 0;

    if (!isValidData(userFirstName) && !isValidData(userLastName) && !isValidData(userArtistName)) {
        return null;
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column', // Stack children vertically
            alignItems: 'center', // Center children horizontally
            flexWrap: 'wrap', // Allow items to wrap
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'center', // Center children horizontally
                gap: '5px', // Small gap between the items
                color: '#493657',
                marginLeft: '10px',
                marginRight: '10px',
                flexWrap: 'wrap', // Allow names to wrap onto the next line
            }}>
                {isValidData(userFirstName) && <div>{userFirstName}</div>}
                {isValidData(userLastName) && <div>{userLastName}</div>}
            </div>
            {isValidData(userArtistName) && (
                <div style={{
                    fontSize: '0.8em', // Smaller font size
                    color: '#666', // Lighter shade of black
                    marginTop: '2px' // Add some space above the artist name
                }}>
                    {userArtistName}
                </div>
            )}
        </div>
    );
}

export default UserName;