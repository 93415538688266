import React, { useState, useEffect } from 'react';

type UserProfileProps = {
    userId: string;
    userData: any;
};

const UserCategories: React.FC<UserProfileProps> = ({ userId, userData }) => {
    const [categoryNames, setCategoryNames] = useState<string[]>([]);
    const API_KEY = process.env.REACT_APP_API_KEY; // Access the API key from the environment variable

    const [error, setError] = useState<string | null>(null); // Add this state variable at the beginning of your component

    useEffect(() => {
        try {
            if (userData && userData.user_info && userData.user_info.getUserProfile &&
                userData.user_info.getUserProfile.categories && userData.user_info.getUserProfile.categories.items) {
                const categories = userData?.user_info?.getUserProfile.categories.items;
                const namesSet = new Set(categories.map((category: {
                    name: { en: any; };
                    parent: { name: { en: any; }; };
                    mainCategory: { name: { en: any; }; };
                }) => {
                    const categoryName = category.name.en;
                    const parentName = category.parent?.name?.en;
                    const mainCategoryName = category.mainCategory?.name?.en;
                    const parentString = parentName && parentName !== mainCategoryName ? `(${parentName})` : '';
                    const mainCategoryString = mainCategoryName ? `(${mainCategoryName})` : '';
                    return [categoryName, parentString, mainCategoryString].filter(part => part).join(' ');
                }));
            }
            // @ts-ignore
            setCategoryNames(Array.from(namesSet));
        } catch(error) {
            console.error(error);
            // @ts-ignore
            setError(error.message);
        }
    }, [userId, userData]);

    if (!categoryNames.length) {
        return null;
    }

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>
            {categoryNames.map((name, index) => (
                <div key={index} style={{
                    background: '#f0f0f0',
                    borderRadius: '8px',
                    padding: '5px 10px',
                    margin: '5px',
                    fontSize: '0.7em',
                    wordWrap: 'break-word',
                    maxWidth: '140px',
                }}>
                    {name}
                </div>
            ))}
        </div>
    );
}

export default UserCategories;
