import React, {useEffect, useState} from 'react';
import './App.css';
import UserProfile from "./components/UserProfile";
import UserAbout from "./components/center/UserAbout";
import UserBackPicture from "./components/UserBackPicture";
import UserCategories from "./components/center/UserCategories";
import UserCity from "./components/center/UserCity";
import UserEmail from "./components/socials_contact/UserEmail";
import UserFacebook from "./components/socials_contact/UserFacebook";
import UserFrontPicture from "./components/UserFrontPicture";
import UserInstagram from "./components/socials_contact/UserInstagram";
import UserIsPremium from "./components/center/UserIsPremium";
import UserIsPro from "./components/UserIsPro";
import UserName from "./components/center/UserName";
import UserPhone from "./components/socials_contact/UserPhone";
import UserTwitter from "./components/socials_contact/UserTwitter";
import UserWebsite from "./components/socials_contact/UserWebsite";
import UserYoutube from "./components/socials_contact/UserYoutube";
import QRCodeButton from './components/QRcode/QRCodeButton';
import {useLoading} from "./components/loading_screen/LoadingContext";
import FullScreenLoading from "./components/loading_screen/FullScreenLoading";
import QRShareButton from "./components/QRcode/QRShareButton";
import QRCodeDisplay from "./components/QRcode/QRCodeButton";

// http://localhost:3000/?userId=680a9c83-c55d-4204-8a40-4adfaf2f286f

// TODO: use Vault to secure BackendAPI!!

function App() {
    const [userId, setUserId] = useState('');
    const { isLoading } = useLoading(); // Use the isLoading from context
    // process.env.REACT_APP_API_URL || ;
    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8000';
    const [userData, setUserData] = useState<unknown | null>(null);
    const apiKey = process.env.REACT_APP_API_KEY; // Access the API key from the environment variables

    useEffect(() => {
        // Extract the user ID from the URL query parameter
        const queryParams = new URLSearchParams(window.location.search);
        const userIdFromURL = queryParams.get('userId');
        if (userIdFromURL) {
            setUserId(userIdFromURL);
            // No need to call startLoading or stopLoading here since individual components handle it
        }
        if (!apiKey) {
            console.error('API_KEY is not defined. Check your .env file.');
            return; // Early return if API_KEY is not set
        }

        console.log("Fetching data..."); // Log before fetching

        // Fetch user profile data using apiUrl and apiKey
        fetch(`${apiUrl}/user/${userId}`, {
            headers: new Headers({
                "x-api-key": apiKey // Include the apiKey in the request headers
            })
        })
            .then(response => {
                console.log("Response received"); // Log when response received
                return response.json();
            })
            .then(data => {
                console.log("Data processed:", data); // Log processed data
                setUserData(data);
            })
            .catch(error => {
                console.error('Error:', error); // Log error
            });
    }, [userId, apiUrl, apiKey, userData]); // Add userId, apiUrl, and apiKey as dependencies

    if (!userData) {
        return <div></div>;
    }

    if (isLoading) {
        return <FullScreenLoading />;
    }


    return (
        <div className="App" style={{ maxWidth: '300px', width: '100%', margin: '0 auto', height: '1000px' }}>
            <div className="Top"
                 style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px'}}>
                <div className="image-stack">
                    {userId && userData ? <UserBackPicture userId={userId} userData={userData}/> : <div>No user ID provided</div>}
                    {userId && userData ? <UserFrontPicture userId={userId} userData={userData}/> : <div>No user ID provided</div>}
                </div>

                {/*{userId ? <UserProfile userId={userId} apiUrl={apiUrl}/> : <div>No user ID provided</div>}*/}
                {userId ? <UserCategories userId={userId} userData={userData}/> : <div>No user ID provided</div>}

                <div style={{
                    background: '#f0f0f0',
                    borderRadius: '8px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '10px'
                }}>
                    {userId ? (
                        <div style={{
                            marginTop: '5px',
                            marginBottom: '0'
                        }}> {/* Adjust top margin to match the bottom margin */}
                            <UserIsPremium userId={userId} userData={userData}/>
                        </div>
                    ) : (
                        <div style={{margin: '5px 0'}}>No user ID provided</div>
                    )}
                    <h2 style={{
                        width: '100%',
                        textAlign: 'center',
                        margin: '5px 0'
                    }}> {/* Unified margin for consistency */}
                        {userId ? <UserName userId={userId} userData={userData}/> : <div>No user ID provided</div>}
                    </h2>
                    {userId ? <UserCity userId={userId} userData={userData}/> :
                        <div style={{margin: '5px 0'}}>No user ID provided</div>}
                    <p style={{margin: '5px 0'}}>{userId ? <UserAbout userId={userId} userData={userData}/> :
                        <div>No user ID provided</div>}</p>
                </div>


                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '20px',
                    color: '#493657',
                    marginLeft: '10px',
                    marginRight: '10px',
                    flexWrap: 'wrap'
                }}>
                    {userId ? <UserEmail userId={userId} userData={userData}/> : <div>No user ID provided</div>}
                    {userId ? <UserWebsite userId={userId} userData={userData}/> : <div>No user ID provided</div>}
                    {userId ? <UserPhone userId={userId} userData={userData}/> : <div>No user ID provided</div>}
                </div>

                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '20px',
                    color: '#493657',
                    marginLeft: '10px',
                    marginRight: '10px',
                    flexWrap: 'wrap'
                }}>
                    {userId ? <UserFacebook userId={userId} userData={userData}/> : <div>No user ID provided</div>}
                    {userId ? <UserInstagram userId={userId} userData={userData}/> : <div>No user ID provided</div>}
                    {userId ? <UserTwitter userId={userId} userData={userData}/> : <div>No user ID provided</div>}
                    {userId ? <UserYoutube userId={userId} userData={userData}/> : <div>No user ID provided</div>}
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '20px',
                    color: '#f0f0f0',
                    marginLeft: '10px',
                    marginRight: '10px',
                    flexWrap: 'wrap'
                }}>
                    <QRCodeDisplay/>
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '20px',
                    color: '#f0f0f0',
                    marginLeft: '10px',
                    marginRight: '10px',
                    flexWrap: 'wrap'
                }}>
                    <div>©2024 - The Pending</div>
                </div>

            </div>
        </div>
    );
}

export default App;